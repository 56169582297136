import React from "react"
import { graphql } from "gatsby"
import CardsContainer from "@global/cards/CardsContainer"
import Seo from "@elements/Seo"
import Cta from "@global/cards/Cta"
import Layout from "@layouts/layout"
// import { runningPlanCtaConfig } from "@helpers/listHelper"
import Block from "@global/layout/Block"
import Button from "@elements/Button"
import Banner from "@global/layout/Banner"
import Content from "@elements/Content"

const RunningPlans = ({ data, pageContext: { title, parent } }) => {
  const runningPlans = data.runningPlans.nodes
  const trainingTipsPage = data.trainingTipsPage.nodes[0]

  return (
    <Layout title={title} parent={parent}>
      <Seo
        title={data.content.runningPlans.meta.title}
        description={data.content.runningPlans.meta.desc}
        image={data.banner.runningPlansBanner}
      />
      <Banner image={data.banner.runningPlansBanner} hiddenSmall={true} />
      <Block padding="double-top" gutters={true}>
        <Content html={data.content.runningPlans.content} />
      </Block>

      {trainingTipsPage && (
        <Block padding="top" gutters={true}>
          <Button
            text={trainingTipsPage.pageContext.title}
            url={trainingTipsPage.path}
          />
        </Block>
      )}

      <Block padding="both" gutters={true}>
        <CardsContainer heading={"plans"} modifier={"ctas"}>
          {runningPlans.map(runningPlan => {
            const { id, banner, title, distance, path } = runningPlan

            return (
              <Cta
                key={`runplan-${runningPlan.id}`}
                heading={title}
                subHeading={distance + " KM"}
                image={banner}
                link={{ title, path }}
                id={id}
              />
            )
          })}
        </CardsContainer>
      </Block>
    </Layout>
  )
}

export default RunningPlans

// TODO: Change allSitePage filter to eq /training-and-event-tips page once it has been created

export const query = graphql`
  query runningPlansQuery {
    runningPlans: allDatoCmsRunningPlan(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        slug
        title
        distance
        path
        banner {
          gatsbyImageData(imgixParams: { fit: "crop", w: "664", h: "400" })
        }
      }
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      runningPlans {
        content
        meta {
          title
          desc
        }
      }
    }
    banner: datoCmsGlobal {
      runningPlansBanner {
        gatsbyImageData(
          imgixParams: { w: "1280", h: "640", fit: "crop", auto: "true" }
        )
        url(imgixParams: { w: "1000", fit: "max", fm: "jpg" })
      }
    }
    trainingTipsPage: allSitePage(
      filter: { path: { eq: "/guide-to-working-out/training-and-event-tips" } }
    ) {
      nodes {
        id
        path
        pageContext
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`
